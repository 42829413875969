<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form label-width="120px" :inline="true" size="min" :model="searchForm" ref="searchForm">
          <el-row>
            <el-col :md="8">
              <el-form-item label="中文品名:">
                <el-input size="small" v-model.trim="searchForm.cust_abbr" clearable placeholder="请填写中文品名" />
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="模具类型:">
                <el-select filterable v-model="searchForm.mdin_type" placeholder="请选择模具类型" size="small" clearable>
                  <el-option v-for="item in mouldTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="模具编号:">
                <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写模具编号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <el-form-item label="要求交样日期:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="创建时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMmplsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete" icon="el-icon-delete"> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadFlag"
            class="vg_cursor"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="非毛绒委托打样单号" prop="requ_no" min-width="100" align="center" />
            <el-table-column label="模具编号" prop="mdnc_no" align="center" />
            <el-table-column label="模具属性" prop="mdnc_part_attr" align="center" min-width="100" />
            <el-table-column label="中文品名" prop="mdnc_name" align="center" />
            <el-table-column
              label="模具材质"
              prop="mdnc_part_mtqt"
              align="center"
              :formatter="({ mdnc_part_mtqt }) => getMdncPartMtqt(mdnc_part_mtqt)"
            />
            <el-table-column
              label="模具类型"
              prop="mdnc_part_type"
              align="center"
              :formatter="({ mdnc_part_type }) => getMdncPartType(mdnc_part_type)"
            />
            <el-table-column label="开模日期" prop="mmpl_time" align="center" :formatter="({ mmpl_time }) => getDateNoTime(mmpl_time)" />
            <el-table-column label="开模周期" prop="mmpl_period" align="center" />
            <el-table-column label="模具数量" prop="mmpl_num" align="center" />
            <el-table-column label="模具生产商" prop="mmpl_manu" align="center" :formatter="({ mmpl_manu }) => getManu(mmpl_manu)" />
            <el-table-column label="模具存放地" prop="mdnc_part_supp" align="center" />
            <el-table-column
              label="要求交样时间"
              prop="mmpl_redate"
              align="center"
              min-width="100"
              :formatter="({ mmpl_redate }) => getDateNoTime(mmpl_redate)"
            />
            <el-table-column label="录入人" prop="stff_name" align="center" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" align="center" :formatter="({ mmpl_time }) => getDateNoTime(mmpl_time)" />
            <el-table-column label="单据状态" show-overflow-tooltip align="center">
              <template v-slot="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                  {{ helper.getStatusName(scope.row.status).name }}</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mmplAPI } from '@api/modules/mmpl';
import pubPagination from '@/components/common/pubPagination';
import selectStatus from '@/views/component/selectStatus';
import MmplListRfid from './TabChild/Componet/TabPanel/MmplListRfid.vue';
import { optnAPI } from '@api/modules/optn';
import MmplEditMail from './TabChild/MmplEditMail.vue';
import { userAPI } from '@api/modules/user';
import { getDateNoTime } from '@assets/js/dateUtils';

export default {
  name: 'mmplList',
  components: {
    pubPagination,
    selectStatus,
    MmplListRfid,
    MmplEditMail
  },
  data() {
    return {
      searchForm: {},
      mouldTypeList: [
        { id: 0, label: '生产模' },
        { id: 1, label: '样品模' }
      ],
      postList: [
        { id: 0, lable: '已完成', value: '1' },
        { id: 1, lable: '未完成', value: '0' }
      ],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      tableData1: [],
      tableDataList: [],
      multiSelection: [],
      btn: {},
      mmplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      loadFlag: true,
      isDyj: true,
      beloDeptId: null,
      mmplFlag: false,
      title: '收件人邮箱确认',
      userInfo: {},
      deptList: [],
      mmplPropList: [],
      stffType: [],
      totalnum: '',
      mouldTextList: [
        { id: 1, label: '橡胶:01' },
        { id: 2, label: 'PA6:02' },
        { id: 3, label: 'TPR:03' },
        { id: 4, label: 'TPE:04' },
        { id: 5, label: 'TPU:05' },
        { id: 6, label: 'PP:06' },
        { id: 7, label: 'ABS:07' },
        { id: 8, label: 'PET:08' },
        { id: 9, label: '吹塑EVA:09' },
        { id: 10, label: '射出EVA:10' }
      ]
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  watch: {
    $route(to, from) {
      if (from.path === '/mmpl_edit') {
        this.getMmpls();
      }
    }
  },
  filters: {
    // 样品类型转换
    formatMmplType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    }
  },
  methods: {
    /**
     * 格式转换
     */
    getDateNoTime,
    getManu(val) {
      switch (val) {
        case 1:
          return '模房';
        case 2:
          return '工厂';
        default:
          return '暂无';
      }
    },
    getMdncPartType(val) {
      switch (val) {
        case 1:
          return '样品模';
        case 2:
          return '生产模';
        default:
          return '暂无';
      }
    },
    getMdncPartMtqt(val) {
      return this.mouldTextList.find(x => x.id === val)?.label ?? '暂无';
    },

    rework(row) {
      this.$confirm('是否重做?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        const { perm_id } = this.$route.query;
        this.jump('/mmpl_edit', { perm_id: perm_id, form_id: row.mmpl_id, rework: true });
      });
    },
    initData() {
      this.getMmpls();
      this.getOptnByPermId();
      this.getStffType();
    },
    getOptnByPermId() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 }).then(res => {
        if (res.data.code === 0) {
          this.userInfo = this.$cookies.get('userInfo');
          let deptList = [{ param2: null, param1: '全部' }];
          let temp = res.data.data.form.optn_cntt_list;
          let temp1 = [];
          if (process.env.NODE_ENV === 'production') {
            if (this.userInfo.dept_id === 2) {
              temp.forEach(item => {
                if (item.param2 === '2') {
                  temp1.push(item);
                }
              });
              this.deptList = temp1;
            } else if (this.userInfo.dept_id === 17) {
              temp.forEach(item => {
                if (item.param2 === '17') {
                  temp1.push(item);
                }
              });
              this.deptList = temp1;
            } else {
              this.deptList = deptList.concat(temp);
            }
          } else {
            if (this.userInfo.dept_id === 59) {
              temp.forEach(item => {
                if (item.param2 === '59') {
                  temp1.push(item);
                }
              });
              this.deptList = temp1;
            } else if (this.userInfo.dept_id === 60) {
              temp.forEach(item => {
                if (item.param2 === '60') {
                  temp1.push(item);
                }
              });
              this.deptList = temp1;
            } else {
              this.deptList = deptList.concat(temp);
            }
          }
        }
      });
    },
    // 获取产品信息
    getMmpls() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      this.searchForm.start_time = timeNewVal.startTime;
      this.searchForm.end_time = timeNewVal.endTime;
      this.searchForm.page_no = this.currentPage;
      get(mmplAPI.getMmpls, this.searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          this.totalnum = res.data.data.mmpl_num_total;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        }
      });
    },

    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1, {}).then(res => {
        if (res.data.code === 0) {
          let stffType = res.data.data;
          let map = new Map();
          for (let item of stffType) {
            map.set(item.stff_name, item);
          }
          stffType = [...map.values()];
          this.stffType = stffType;
        }
      });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
      }
      this.loadFlag = true;
      this.$refs.selectStatus.changeValue();
      this.currentPage = 1;
      this.getMmpls();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 查询方法
    getMmplsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMmpls();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMmpls();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    //删除提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '已生效的数据不可删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      let ids = this.multiSelection.map(({ mmpl_id }) => Object.values({ mmpl_id }).toString());
      post(mmplAPI.deleteMmplByIds, { mmpl_id_list: ids }).then(res => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg);
          if (ids.length === this.tableData.length && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.$refs.pubPagination.currentPage = this.currentPage;
          }
        }
        this.getMmpls();
      });
    },
    //邮寄获取数据提示
    getDataPrompt() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status !== 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '未生效的数据不可更改邮寄状态！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.MailImport();
        }
      }
    },
    // 邮寄状态确认弹框
    postConfirm() {
      this.$confirm('请确认勾选的样品工单已寄出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.changePostStatus();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 更改样单邮寄状态
    changePostStatus() {
      const ids1 = [];
      this.multiSelection.map(item => {
        ids1.push(item.mmpl_id);
      });
      const ids2 = [];
      this.multiSelection.map(item => {
        ids2.push(item.requ_id);
      });
      post(mmplAPI.addMmplMail, {
        requ_id_list: ids2,
        mmpl_id_list: ids1
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '更改成功'
          });
        }
        this.getMmpls();
      });
    },
    //邮寄弹窗
    MailImport() {
      this.mmplPropList = this.multiSelection;
      this.mmplFlag = true;
    },
    //邮寄取消
    MailCancel() {
      this.mmplFlag = false;
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/mmpl_edit', { perm_id: permId, form_id: row.mmpl_id });
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      if (val.order === 'ascending') {
        this.searchForm.mtrl_sort = 'asc';
        this.searchForm.mtrl_field = val.prop;
      } else if (val.order === 'descending') {
        this.searchForm.mtrl_sort = 'desc';
        this.searchForm.mtrl_field = val.prop;
      } else {
        this.searchForm.mtrl_sort = '';
        this.searchForm.mtrl_field = '';
      }
      this.searchForm.page_no = 1;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button_group {
  // width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img {
  margin-top: 5px;
  width: 40px;
}
.vd_notpass {
  color: rgb(187, 187, 187);
}
.vd_pass {
  color: #188dff;
}
.vd_complate {
  color: #36ab60;
}
.vd_disable {
  color: #d81e06;
}
.vd_wei {
  color: #ff8511;
}
.reworkBtn {
  z-index: 999;
  background: #f3941c;
  border-color: #f3941c;
}
</style>
