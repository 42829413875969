<template>
  <div>
    <div v-if="numState === 1" class="vd_tenpd">
      <img src="@/assets/image/notpass.png" alt="" class="vd_img" />
      <div class="vd_notpass">未经历</div>
    </div>
    <div v-else-if="numState === 2" class="vd_tenpd">
      <img src="@/assets/image/pass.png" alt="" class="vd_img" />
      <span class="vd_pass">正在经历</span>
    </div>
    <div v-else-if="numState === 3" class="vd_tenpd">
      <img src="@/assets/image/complate.png" alt="" class="vd_img" />
      <span class="vd_complate">已完成</span>
    </div>
    <div v-else-if="numState === 0" class="vd_tenpd">
      <img src="@/assets/image/disable.png" alt="" class="vd_img" />
      <span class="vd_disable">不经历</span>
    </div>
    <div v-else-if="numState === 4" class="vd_tenpd">
      <img src="@/assets/image/wei.png" alt="" class="vd_img" />
      <span class="vd_wei">已拒绝</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numState: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img {
  margin-top: 5px;
  width: 40px;
}
.vd_notpass {
  color: rgb(187, 187, 187);
}
.vd_pass {
  color: #188dff;
}
.vd_complate {
  color: #36ab60;
}
.vd_disable {
  color: #d81e06;
}
.vd_wei {
  color: #ff8511;
}
</style>
